import React, { FC, useState } from 'react';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { deriveLaunchState, Mission } from 'superclient';
import ImageSanity from '../components/core/image-sanity';
import AstronautCardList from '../components/astronauts/astronaut-card-list';
import CraftList from '../components/missions/mission-craft-list';
import AgencyNationGrid from '../components/core/agency-nation-grid';
import SubmitFeedback from '../components/core/submit-feedback';
import MetaModule from '../components/core/meta-module';

const TemplateMission: FC<{ pageContext: { mission: Mission } }> = ({
  pageContext: { mission }
}) => {
  const [mouseOverDataMissing, setMouseOverDataMissing] = useState(-1);
  const [mouseOverFeedback, setMouseOverFeedback] = useState(-1);

  const launchDateISO = mission.launchDate
    ? mission.launchDate.utc
    : mission.vagueLaunchDate && mission.vagueLaunchDate.split('-').length === 3
    ? DateTime.fromObject({
        year: parseInt(mission.vagueLaunchDate.split('-')[0], 10),
        month: parseInt(mission.vagueLaunchDate.split('-')[1], 10),
        day: parseInt(mission.vagueLaunchDate.split('-')[2], 10)
      }).toISO()
    : undefined;

  const { launchState } = deriveLaunchState(launchDateISO);

  const nameCard = (p) => (
    <div className="">
      <div className={cx(`astronaut_page__header_split rel f fc ${p}`)}>
        <div className="astronaut_page__name_card">
          <div className="f fr">
            <div className={cx('astronaut_page__name_desktop fa mt025 mr05')}>
              {mission.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const stats = (px, mobile = false) => (
    <div
      className={cx(`f fc ${px} py1 f1`, {
        astronaut_page__header_split: !mobile
      })}
    >
      <div className="f f1 aic fr pb1">
        <div className="f fc f1">
          <div className="mt1 akkura small caps">Launch Date</div>
          <div className="h4">{DateTime.fromISO(launchDateISO).toFormat('DDD')}</div>
        </div>
        <div className="f fc f1">
          <div className="mt1 akkura small caps">Craft</div>
          <div className="h4">
            {mission.spacecrafts &&
              mission.spacecrafts.map((c) => c.title).join(', ')}
          </div>
        </div>
      </div>

      <div className="f f1 aic fr pb1">
        <div className="f fc f1">
          <div className="mt1 akkura small caps">Status</div>
          <div className="h4">{launchState}</div>
        </div>
        <div className="f fc f1">
          <div className="mt1 akkura small caps">Crew</div>
          <div className="h4">
            {mission.astronauts ? mission.astronauts.length : 0}
          </div>
        </div>
      </div>
    </div>
  );

  const hasOverview =
    mission.shortDescription && mission.shortDescription.length > 0;

  const hasAgenciesOrNations =
    (mission.agencies || []).length + (mission.nations || []).length > 0;

  const dataMissing = (title, i) => (
    <div
      className="rel h4 under f jcc"
      onMouseEnter={() => setMouseOverDataMissing(i)}
      onMouseLeave={() => {
        setMouseOverDataMissing(-1);
      }}
    >
      <span className="pointer">{title}</span>
      <div
        className="abs jcc "
        style={{
          display:
            mouseOverDataMissing === i || mouseOverFeedback === i ? 'flex' : 'none'
        }}
        onMouseEnter={() => setMouseOverFeedback(i)}
        onMouseLeave={() => {
          setMouseOverFeedback(-1);
        }}
      >
        <SubmitFeedback />
      </div>
    </div>
  );

  const launchCrewIds = (mission.astronauts || [])
    .sort((a, b) => a._id.localeCompare(b._id))
    .map((a) => a._id)
    .join(', ');
  const landingCrewIds = (mission.landingCrew || [])
    .sort((a, b) => a._id.localeCompare(b._id))
    .map((a) => a._id)
    .join(', ');
  const crewsVary = launchCrewIds !== landingCrewIds;

  return (
    <div className="template__missions">
      <MetaModule
        title={mission.name}
        path={`/missions/${mission.slug.current}`}
        sanityImage={mission.image}
      />

      <div className="astronaut_detail_header">
        <div className="astronaut_detail_header__bar">
          <div className="container--xl y mxa f fr aic px075">
            <div
              className={'pr1 clickable'}
              onClick={() => {
                if (typeof window !== `undefined`) {
                  window.history.back();
                }
              }}
            >
              <span className="astronaut_detail_header__arrow" />
            </div>
          </div>
        </div>
      </div>

      <div className="astronaut_page__header_mobile bcb cw">
        <div className="astronaut_page__image bcb f1 rel f aic jcc">
          <div className="abs top left x y f aic jcc">
            {mission.image && (
              <div
                style={{
                  position: 'relative',
                  width: '50%',
                  paddingBottom: '50%'
                }}
              >
                <ImageSanity
                  className="abs x y top left"
                  image={mission.image}
                  alt={mission.name}
                  width={500}
                />
              </div>
            )}
          </div>
        </div>

        {nameCard('px1 py15')}
        {stats('px1', true)}
      </div>

      <div className="astronaut_page__header_desktop x f fr rel bcb cw">
        <div className="f f1 fr">
          <div className="astronaut_page__image bcb f1 rel">
            <div className="abs top left x y f aic jcc">
              {mission.image && (
                <div
                  style={{
                    position: 'relative',
                    width: '70%',
                    paddingBottom: '70%'
                  }}
                >
                  <ImageSanity
                    className="abs x y top left"
                    image={mission.image}
                    alt={mission.name}
                    width={800}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="f1 rel f fc" />
        </div>

        <div className="astronaut_page__stats x f fr">
          <div className="astronaut_page__image f1 rel">
            {!mission.image && (
              <div className="abs x y f aic jcc">
                {dataMissing('Patch Missing', 1)}
              </div>
            )}
          </div>
          <div className="f1 rel f fc">
            {nameCard('px2 py2')}
            {stats('px2')}
          </div>
        </div>
      </div>

      <div className="bcg">
        {(hasAgenciesOrNations || hasOverview) && (
          <div className="p1 pt2 container--xl mxa">
            {hasOverview && (
              <>
                <div className=" pb1 h5 caps">Overview</div>
                <div className="h4">{mission.shortDescription}</div>
              </>
            )}
            <AgencyNationGrid
              agencies={mission.agencies}
              nations={mission.nations}
            />
          </div>
        )}

        {mission.spacecrafts && mission.spacecrafts.length > 0 && (
          <div className="bcw">
            <CraftList crafts={mission.spacecrafts} />
          </div>
        )}

        <div className="bcb">
          {mission.astronauts && mission.astronauts.length > 0 && (
            <AstronautCardList
              title={crewsVary ? 'Launch Crew' : 'Crew'}
              astronauts={mission.astronauts}
            />
          )}
          {crewsVary && mission.landingCrew && mission.landingCrew.length > 0 && (
            <AstronautCardList
              title={'Landing Crew'}
              astronauts={mission.landingCrew}
            />
          )}
          {mission.animalCrew && mission.animalCrew.length > 0 && (
            <AstronautCardList
              title={'Non-human Crew'}
              astronauts={mission.animalCrew}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateMission;
