import React, { FC } from 'react';
import { Craft } from 'superclient';
import HorizontalList from '../core/horizontal-list';
import ImageSanity from '../core/image-sanity';

const CraftList: FC<{ crafts: Craft[] }> = ({ crafts }) => {
  return (
    <HorizontalList title="Crafts">
      {crafts.map((spacecraft, i) => (
        <div
          key={spacecraft._id}
          className="py1 rel"
          style={{ width: 'min(80vw, 300px)' }}
        >
          {spacecraft.filledBlackIcon && (
            <div
              className="f aic"
              style={{
                minHeight: 90
              }}
            >
              <div
                className="rel"
                style={{
                  width:
                    50 *
                    spacecraft.filledBlackIcon.asset.metadata.dimensions.aspectRatio,
                  height: 50
                }}
              >
                <ImageSanity
                  className="abs x y top left"
                  image={spacecraft.filledBlackIcon}
                  alt={spacecraft.title}
                  width={50}
                />
              </div>
            </div>
          )}
          <div className="f1 p5">{spacecraft.title}</div>
          <div className="f1 p mt1">{spacecraft.shortDescription}</div>
          {i < crafts.length - 1 && crafts.length > 1 && (
            <div className="abs width-1 bcb right top bottom" />
          )}
        </div>
      ))}
    </HorizontalList>
  );
};

export default CraftList;
